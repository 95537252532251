import React from "react";
import NavLink from "../../../Header/NavLink";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Icon from "../../../Icon";
import { prependDomain } from "../../../../support/helpers";

/**
 * List of call to action buttons on the home page
 */
const HomeButtons = () => {
    const homeButtons = useSelector(
        (state) => state.root.styling?.microContentBlocks?.homeButtons
    );
    const serverOrigin = useSelector((state) => state.root.serverOrigin);

    let origin = serverOrigin;
    if (typeof window !== "undefined" && window !== null) {
        origin = window.origin;
    }

    if (!homeButtons) return null;

    return (
        <div className="row align-items-stretch">
            {homeButtons.map((button, i) => (
                <HomeButton
                    key={`home-button${i}`}
                    className="col-md-4 mb-3 mb-md-0"
                >
                    <div className={`text-center bg-white p-3 h-100`}>
                        <NavLink
                            className="text-decoration-none"
                            to={{
                                pathname: prependDomain(origin, button.link),
                            }}
                        >
                            <div className="text-primary-dark-alt">
                                <Icon size={72} icon={button.icon} />
                            </div>
                            <div className="h3 text-primary-dark-alt">
                                {button.label}
                            </div>
                            <div className="text-black">{button.text}</div>
                        </NavLink>
                    </div>
                </HomeButton>
            ))}
        </div>
    );
};

const HomeButton = styled.div`
    &:last-child {
        margin-bottom: 0 !important;
    }
    > div&:hover {
        background-color: var(--bs-light-gray) !important;
    }
`;

export default HomeButtons;
