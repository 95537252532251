import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHome } from "../../../hooks/useHome";
import Container from "./Container";
import Loading from "../../Loading";
import { getPageType } from "../../../support/helpers";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { setPageError } from "../../../reducers/rootReducer";
import { setPageHeaderInfo } from "../../../reducers/pagesReducer";

const Home = () => {
    const dispatch = useDispatch();
    const { myNicheTitleTagText, nichetypeSingular, nicheDomain2ndLevel } =
        useSelector((state) => state.root.styling);
    const index = useSelector((state) => state.pages.index);
    const routeParams = useParams();
    const { isLoading, execute, error } = useHome();
    const isHomePage = getPageType(routeParams, "index");

    useEffect(() => {
        dispatch(setPageHeaderInfo(index?.pageHeaderInfo));
    }, [dispatch, routeParams]);

    useEffect(() => {
        if (!index) {
            const fetchData = async () => {
                bulkPageReducer(dispatch, await execute());
            };
            fetchData();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (error) {
            dispatch(
                setPageError({
                    page: "Home",
                    error: "Error loading home page.",
                })
            );
        }
    }, [dispatch, error]);

    return (
        <>
            {isLoading || !index ? (
                <Loading />
            ) : (
                <Container
                    key={`home-wrapper`}
                    indexData={index}
                    isHomePage={isHomePage}
                />
            )}
        </>
    );
};

export default Home;
